import React from 'react'
import PropTypes from 'prop-types'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import LinkedHeader from 'elements/Typography/LinkedHeader'
import { Text, List, ListItem } from '@chakra-ui/core'

const components = {
  /* eslint-disable react/display-name,  react/prop-types */
  p: ({ children }) => <Text fontSize="md">{children}</Text>,
  ul: ({ children }) => <List styleType="disc">{children}</List>,
  li: ({ children }) => <ListItem>{children}</ListItem>,
  h2: ({ children }) => <LinkedHeader mb={4} mt={8} variant="h2">{children}</LinkedHeader>,
  h3: ({ children }) => <LinkedHeader mb={3} mt={8} variant="h3">{children}</LinkedHeader>,
  h4: ({ children }) => <LinkedHeader my={4} variant="h4">{children}</LinkedHeader>,
  h5: ({ children }) => <LinkedHeader my={2} variant="h5">{children}</LinkedHeader>,
  h6: ({ children }) => <LinkedHeader variant="h6">{children}</LinkedHeader>
}

function MdxRenderer ({ body }) {
  return (
    <MDXProvider components={components}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  )
}

MdxRenderer.propTypes = {
  body: PropTypes.node
}

export default MdxRenderer
