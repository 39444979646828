import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from 'elements/Frame/SEO'
import Article from 'elements/Frame/Article'
import Title from 'elements/Typography/Title'
import MdxRenderer from 'elements/Markdown/MdxRenderer'

export default function InfoTemplate ({ data }) {
  const { mdx } = data
  const {
    frontmatter: { title, description, excerpt },
    body,
    // eslint-disable-next-line no-unused-vars
    mdxAST
  } = mdx

  return (
    <>
      <SEO title={title} description={description || excerpt} />

      <Article>
        <header>
          <Title>{title}</Title>
        </header>
        <MdxRenderer body={body} />
      </Article>
    </>
  )
}

InfoTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      headings {
        depth
        value
      }
      body
      mdxAST
      frontmatter {
        title
      }
    }
  }
`
