import React from 'react'
import PropTypes from 'prop-types'
import headerSlugify from 'utils/headerSlugify'
import { Heading } from '@chakra-ui/core'

const variants = {
  h1: '2xl',
  h2: 'xl',
  h3: 'lg',
  h4: 'md',
  h5: 'sm',
  h6: 'xs'
}
function LinkedHeader ({ variant, children, ...rest }) {
  // This handles things like inline code in headers

  const text = (Array.isArray(children) ? children : [children]).reduce((a, c) => {
    a = a + (c.props ? c.props.children : c)
    return a
  }, '')
  const hash = headerSlugify(text)
  return (
    <Heading id={hash} as={variant} {...rest} size={variants[variant]}>
      <a name={hash}></a>
      {children}
    </Heading>
  )
}

LinkedHeader.propTypes = {
  variant: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default LinkedHeader
