import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/core'

function Article ({ children }) {
  return <Box as="article" fontSize= "1rem" letterSpacing="normal !important" lineHeight= "25px !important">{children}</Box>
}

Article.propTypes = {
  children: PropTypes.node
}

export default Article
